<template>
  <div class="email">
    <center class="email__wrapper">
      <table class="email__main" width="80%">
        <tr>
          <td style="padding:20px 10px 10px">
            <table width="100px" class="email__main__column">
              <tr>
                <td class="email__main__logo">
                  <img
                    src="@/assets/static-assets/icons/1. Final Logo Baskyt@3x.png"
                    alt="baskyt logo"
                  />
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table width="100%" class="email__main__block email__main__column">
              <tr>
                <td>
                  <table class="email__main__column">
                    <tr>
                      <td>
                        <p class="pt-5 pb-4" style="font-size:25px">
                          <strong>Thank You for your order</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          width="150"
                          src="@/assets/static-assets/email/dguy@3x.png"
                          alt=""
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="pt-4" style="font-size:16px">
                          Baskyt has successfully recieved your order!
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="py-1" style="font-size:13px">
                          Your order Number is: <strong>9267567</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 pb-4">
                        <a
                          class="email__main__button"
                          href="http://www.baskytinc.com"
                          >Track Your Order</a
                        >
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <p class="   py-3 email__main__heading">
              When will it reach?
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <table width="100%" class="email__main__block email__main__column">
              <tr>
                <td class="py-3">
                  <p>
                    Our Baskytor will be at your door at
                  </p>
                  <p style="font-size:35px">
                    <strong>9:45 PM</strong>
                  </p>
                  <p style="font-size:12px;color:gray">
                    2/30/2021 (Today)
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <p class="email__main__heading py-3 ">Shipping Address</p>
          </td>
        </tr>
        <tr>
          <td>
            <table
              width="100%"
              class="email__main__block email__main__column"
              style="text-align:left;padding-left:10px"
            >
              <tr>
                <td class="py-3 pl-3">
                  <p class="mb-0" style="font-size:15px;color:gray">
                    353 street 51, MPCH Minnesota
                  </p>

                  <p class="pt-1" style="font-size:15px;color:gray">
                    Phone: +92 303 5285976
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <hr style="border-top:2px solid gray" class="pt-4" />
          </td>
        </tr>
        <tr>
          <td>
            <p class="  mb-1 pt-3  email__main__heading">
              You can find your purchase information below
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="  email__main__subHeading">
              All prices are estimates & may change
            </p>
          </td>
        </tr>
        <tr>
          <td class="email__main__two-columns py-3">
            <table class="email__main__two-columns__column" style="width:85%">
              <tr>
                <td class="email__main__two-columns">
                  <table
                    class="email__main__two-columns__column"
                    style="width:80px"
                  >
                    <tr>
                      <td>
                        <img
                          width="80px"
                          src="@/assets/static-assets/email/Burager Image@3x.png"
                          alt=""
                        />
                      </td>
                    </tr>
                  </table>

                  <table
                    class="email__main__two-columns__column"
                    style="width:150px;padding-left:15px"
                  >
                    <tr>
                      <td>
                        <p
                          style="font-size:15px;color:Black;font-weight:bolder;margin-bottom:3px;padding-top:10px"
                        >
                          Beef Burger
                        </p>
                        <p style="font-size:12px;color:gray;margin-bottom:3px">
                          Khiva Restaurent
                        </p>
                        <p style="font-size:12px;color:gray">Qty: 1</p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <table class="email__main__two-columns__column" style="width:15%">
              <tr>
                <td>
                  <p
                    style="font-size:15px;color:gray;font-weight:bolder;padding-top:10px"
                  >
                    $ 150
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td class="email__main__two-columns py-3">
            <table class="email__main__two-columns__column" style="width:85%">
              <tr>
                <td class="email__main__two-columns">
                  <table
                    class="email__main__two-columns__column"
                    style="width:80px"
                  >
                    <tr>
                      <td>
                        <img
                          width="80px"
                          src="@/assets/static-assets/email/Burager Image@3x.png"
                          alt=""
                        />
                      </td>
                    </tr>
                  </table>

                  <table
                    class="email__main__two-columns__column"
                    style="width:150px;padding-left:15px"
                  >
                    <tr>
                      <td>
                        <p
                          style="font-size:15px;color:Black;font-weight:bolder;margin-bottom:3px;padding-top:10px"
                        >
                          Beef Burger
                        </p>
                        <p style="font-size:12px;color:gray;margin-bottom:3px">
                          Khiva Restaurent
                        </p>
                        <p style="font-size:12px;color:gray">Qty: 1</p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <table class="email__main__two-columns__column" style="width:15%">
              <tr>
                <td>
                  <p
                    style="font-size:15px;color:gray;font-weight:bolder;padding-top:10px"
                  >
                    $ 150
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td class="email__main__two-columns py-3">
            <table class="email__main__two-columns__column" style="width:85%">
              <tr>
                <td class="email__main__two-columns">
                  <table
                    class="email__main__two-columns__column"
                    style="width:80px"
                  >
                    <tr>
                      <td>
                        <img
                          width="80px"
                          src="@/assets/static-assets/email/Burager Image@3x.png"
                          alt=""
                        />
                      </td>
                    </tr>
                  </table>

                  <table
                    class="email__main__two-columns__column"
                    style="width:150px;padding-left:15px"
                  >
                    <tr>
                      <td>
                        <p
                          style="font-size:15px;color:Black;font-weight:bolder;margin-bottom:3px;padding-top:10px"
                        >
                          Beef Burger
                        </p>
                        <p style="font-size:12px;color:gray;margin-bottom:3px">
                          Khiva Restaurent
                        </p>
                        <p style="font-size:12px;color:gray">Qty: 1</p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            <table
              class="email__main__two-columns__column"
              style="width:15%;text-align:right"
            >
              <tr>
                <td>
                  <p
                    style="font-size:15px;color:gray;font-weight:bolder;padding-top:10px"
                  >
                    $ 150
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <hr style="border-top:2px solid gray" class="pt-4" />
          </td>
        </tr>
        <tr>
          <td class="email__main__two-columns">
            <table
              class="email__main__two-columns__column"
              style="text-align:left;width:85%"
            >
              <tr>
                <td>
                  <p style="font-size:15px;text-align:left">Total Bill</p>
                  <p style="font-size:15px;text-align:left">Delivery Charges</p>
                  <p style="font-size:15px;text-align:left">Admin Charges</p>
                  <p style="font-size:15px;text-align:left;color:red">
                    Promo Code
                  </p>
                  <p style="font-size:15px;text-align:left">Estimated Total</p>
                </td>
              </tr>
            </table>

            <table
              class="email__main__two-columns__column"
              style="text-align:right;width:15%"
            >
              <tr>
                <td>
                  <p style="font-size:15px;color:gray;font-weight:bold">$100</p>
                  <p style="font-size:15px;color:gray;font-weight:bold">$7</p>
                  <p style="font-size:15px;color:gray;font-weight:bold">$5</p>
                  <p style="font-size:15px;color:red;font-weight:bold">-$5</p>
                  <p style="font-size:15px;color:gray;font-weight:bold">$100</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <hr style="border-top:2px solid gray" class="pt-4" />
          </td>
        </tr>
        <!-- <tr>
          <td class="email__main__two-columns email__main__bg-img">
            <table
              class="email__main__two-columns__column--md "
              style="width:60%"
            >
              <tr>
                <td>
                  <img
                    style="width:300px;"
                    src="@/assets/static-assets/email/BANNER-small@3x .png"
                    alt="banner"
                  />
                </td>
              </tr>
            </table>

            <table
              class="email__main__two-columns__column--md pt-5"
              style="width:40%"
            >
              <tr>
                <td class="pb-2">
                  <p style="font-size:17px;color:black">
                    Invite a friend to Baskyt to earn an additional
                    <span style="color:red"> 10%</span> discount on your next
                    order!
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <a
                    class="email__main__button--small"
                    href="http://www.baskytinc.com"
                    >Invite Friends</a
                  >
                </td>
              </tr>
            </table>
          </td>
        </tr> -->
        <tr>
          <td class="email__main__two-columns email__main__bg-img">
            <table
              class="email__main__two-columns__column--md "
              style="width:60%"
            >
              <tr>
                <td>
                  <img
                    style="width:300px;"
                    src="@/assets/static-assets/email/BANNER-small@3x .png"
                    alt="banner"
                  />
                </td>
              </tr>
            </table>

            <table
              class="email__main__two-columns__column--md pt-5"
              style="width:40%"
            >
              <tr>
                <td class="pb-2">
                  <p style="font-size:17px;color:black">
                    Invite a friend to Baskyt to earn an additional
                    <span style="color:red"> 10%</span> discount on your next
                    order!
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <a
                    class="email__main__button--small"
                    href="http://www.baskytinc.com"
                    >Invite Friends</a
                  >
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table class="email__main__block mt-5 w-100">
              <tr class="email__main__column pt-3">
                <td>
                  <p
                    class="pt-3"
                    style="font-size:18px;color:black;margin-bottom:0px"
                  >
                    Have any Questions?
                  </p>
                </td>
              </tr>
              <tr class="email__main__column">
                <td>
                  <p
                    style="font-size:14px;color:gray;margin-bottom:0px;padding-top:10px"
                  >
                    Email us your queries at
                    <span style="color:red">info@baskytinc.com</span>
                  </p>
                </td>
              </tr>
              <tr class="email__main__column ">
                <td>
                  <p style="font-size:14px;color:gray">
                    or call at <span style="color:red">(952) 456-4102</span>
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table class="mt-5 email__main email__footer">
        <tr>
          <td>
            <table class="email__main__column" width="50%">
              <tr>
                <td>
                  <p class="pt-4" style="font-size:12px;color:white">
                    Follow Us
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <a class="ml-3" href="https://www.facebook.com"
                    ><img
                      width="20px"
                      src="@/assets/static-assets/social/facebook.png"
                      alt="facebook"
                  /></a>
                  <a class="ml-3" href="https://www.twitter.com"
                    ><img
                      width="20px"
                      src="@/assets/static-assets/social/twitter.png"
                      alt="twitter"
                  /></a>
                  <a class="ml-3" href="https://www.linkedin.com"
                    ><img
                      width="20px"
                      src="@/assets/static-assets/social/linkedin.png"
                      alt="linkedin"
                  /></a>
                  <a class="ml-3" href="https://www.instagram.com"
                    ><img
                      width="20px"
                      src="@/assets/static-assets/social/instagram.png"
                      alt="instagram"
                  /></a>
                </td>
              </tr>
              <tr>
                <td>
                  <hr style="border-top:2px solid gray" />
                </td>
              </tr>
              <tr>
                <td class="pb-4">
                  <span
                    style="color:white;font-size:9px;border-right:1px solid white"
                    class="px-2"
                    >FAQs</span
                  >
                  <span
                    style="color:white;font-size:9px;border-right:1px solid white"
                    class="px-2"
                    >Privacy Policy</span
                  >
                  <span
                    style="color:white;font-size:9px;border-right:1px solid white"
                    class="px-2"
                    >Terms and Conditions</span
                  >
                  <span style="color:white;font-size:9px" class="px-2"
                    >Unsubscribe</span
                  >
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </center>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
